import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IAFKCharacter } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import './employee.scss';
import './afk-character-dynamic.scss';
import { Alert, Col, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCircleInfo,
  faDiagramProject,
  faSquare
} from '@fortawesome/free-solid-svg-icons';
import { RatingBox } from '../modules/common/components/rating-box';
import { AFKCharacter } from '../modules/afk/common/components/afk-character';
import { AFKItem } from '../modules/afk/common/components/afk-item';
import { CategoryCard } from '../modules/cs/main/components/category-card';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IAFKCharacterNodes {
  nodes: IAFKCharacter[];
}

interface IAFKCharacterEntry {
  currentUnit: IAFKCharacterNodes;
}

interface IProps {
  data: IAFKCharacterEntry;
}

const AFKCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  const [currentTab, setCurrentTab] = useState('Info');

  const profileImage = character.cardImage
    ? getImage(character.cardImage.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-afk'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <>
        <div className="character-top ">
          <div className="left-info">
            <h1>
              <span className="small"></span>
              <strong className={`faction ${character.faction}`}>
                {character.name}
              </strong>
              <span className="sub">Information and Guide</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background faction ${character.faction} ${character.slug}`}
            ></div>
            <GatsbyImage image={profileImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section ">
          <Col xs={12} xl={12}>
            <div className={`content-header faction ${character.faction}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong className={`faction ${character.faction}`}>
                    {character.name}
                  </strong>{' '}
                  is an <strong>{character.rarity}-Level</strong>
                  {character.rarity === 'A' && (
                    <StaticImage
                      src="../images/afk/icons/rarity_a.png"
                      alt="A-Level"
                      className="faction-icon"
                    />
                  )}
                  {character.rarity === 'S' && (
                    <StaticImage
                      src="../images/afk/icons/rarity_s.png"
                      alt="S-Level"
                      className="faction-icon"
                    />
                  )}{' '}
                  character from the <strong>{character.class}</strong>
                  {character.class === 'Mage' && (
                    <>
                      <StaticImage
                        src="../images/afk/icons/class_mage.png"
                        alt="Mage"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.class === 'Marksman' && (
                    <>
                      <StaticImage
                        src="../images/afk/icons/class_mark.png"
                        alt="Marksman"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.class === 'Rogue' && (
                    <>
                      <StaticImage
                        src="../images/afk/icons/class_rogue.png"
                        alt="Rogue"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.class === 'Support' && (
                    <>
                      <StaticImage
                        src="../images/afk/icons/class_support.png"
                        alt="Support"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.class === 'Tank' && (
                    <>
                      <StaticImage
                        src="../images/afk/icons/class_tank.png"
                        alt="Tank"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.class === 'Warrior' && (
                    <>
                      <StaticImage
                        src="../images/afk/icons/class_warrior.png"
                        alt="Warrior"
                        className="faction-icon"
                      />
                    </>
                  )}{' '}
                  class who belongs to the{' '}
                  <strong className={`faction ${character.faction}`}>
                    {character.faction}
                  </strong>
                  {character.faction === 'Graveborn' && (
                    <>
                      <StaticImage
                        src="../images/afk/icons/faction_grave.png"
                        alt="Graveborn"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.faction === 'Lightbearer' && (
                    <>
                      <StaticImage
                        src="../images/afk/icons/faction_light.png"
                        alt="Lightbearer"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.faction === 'Mauler' && (
                    <>
                      <StaticImage
                        src="../images/afk/icons/faction_mauler.png"
                        alt="Mauler"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.faction === 'Wilder' && (
                    <>
                      <StaticImage
                        src="../images/afk/icons/faction_wilder.png"
                        alt="Wilder"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.faction === 'Celestial' && (
                    <>
                      <StaticImage
                        src="../images/afk/icons/faction_cele.png"
                        alt="Celestial"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.faction === 'Hypogean' && (
                    <>
                      <StaticImage
                        src="../images/afk/icons/faction_hypo.png"
                        alt="Hypogean"
                        className="faction-icon"
                      />
                    </>
                  )}{' '}
                  faction. Their auto-attack range is{' '}
                  <strong>{character.range}</strong>.
                </h2>
                <div className="char-intro">
                  {character.intro ? (
                    <>{renderRichText(character.intro, options)}</>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <p className="hide-on-mobile">
                To learn more about{' '}
                <strong className={`faction ${character.faction}`}>
                  {character.name}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for.
              </p>
            </div>
          </Col>
        </Row>
        <>
          <div className="build-relics">
            <div className={`content-header faction ${character.faction}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Last updated
            </div>
            <div className="info-box with-margin">
              <p>
                <strong className={`faction ${character.faction}`}>
                  {character.name}
                </strong>{' '}
                profile has been last updated at:{' '}
                <strong>{character.updatedAt}</strong>.
              </p>
            </div>
          </div>
        </>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <p className="show-on-mobile">
          To learn more about{' '}
          <strong className={`faction ${character.faction}`}>
            {character.name}
          </strong>{' '}
          check the sections below. <strong>Use the tabs</strong> to quickly
          switch to the kind of information you're looking for.
        </p>
        <div className="tabs">
          <div
            className={`single-tab faction ${character.faction} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Profile</p>
          </div>
          <div
            className={`single-tab faction ${character.faction} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review</p>
          </div>
          <div
            className={`single-tab faction ${character.faction} ${
              currentTab === 'Build' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Build')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
            </div>
            <p>Guides</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header faction ${character.faction} `}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div className={`content-header faction ${character.faction}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skills
          </div>
          {character.ultimateDescription ? (
            <>
              <div className={`skills ${character.faction}`}>
                <Row xs={1} xxl={3}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon ${character.faction}`}>
                          Ultimate
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">
                            {character.ultimateDetails.name}
                          </p>
                          <p className="skill-type">
                            Unlocks at level <strong>1</strong>
                          </p>
                        </div>
                      </div>
                      <div className={`additional-information ultimate`}>
                        <p>
                          Cooldown:{' '}
                          <span>
                            {character.ultimateDetails.cooldown ? (
                              <>{character.ultimateDetails.cooldown}s</>
                            ) : (
                              <>-</>
                            )}
                          </span>
                        </p>
                        <p>
                          Range: <span>{character.ultimateDetails.range}</span>
                        </p>
                        <p>
                          Initial Energy:{' '}
                          <span>{character.ultimateDetails.energy}</span>
                        </p>
                      </div>
                      <div className={`skill-description`}>
                        <>
                          {renderRichText(
                            character.ultimateDescription,
                            options
                          )}
                        </>
                      </div>
                      <div className="skill-upgrades">
                        <p>
                          <span>Unlocks at level 51:</span>{' '}
                          {character.ultimateDetails.upgrade_1}
                        </p>
                        <p>
                          <span>Unlocks at level 111:</span>{' '}
                          {character.ultimateDetails.upgrade_2}
                        </p>
                        <p>
                          <span>Unlocks at level 171:</span>{' '}
                          {character.ultimateDetails.upgrade_3}
                        </p>
                        <p>
                          <span>Unlocks at level 231:</span>{' '}
                          {character.ultimateDetails.upgrade_4}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon ${character.faction}`}>
                          Skill I
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">
                            {character.skill1Details.name}
                          </p>
                          <p className="skill-type">
                            Unlocks at level <strong>11</strong>
                          </p>
                        </div>
                      </div>
                      <div className={`additional-information`}>
                        <p>
                          Cooldown:{' '}
                          <span>
                            {character.skill1Details.cooldown ? (
                              <>{character.skill1Details.cooldown}s</>
                            ) : (
                              <>-</>
                            )}
                          </span>
                        </p>
                        <p>
                          Range: <span>{character.skill1Details.range}</span>
                        </p>
                      </div>
                      <div className={`skill-description`}>
                        <>
                          {renderRichText(character.skill1Description, options)}
                        </>
                      </div>
                      <div className="skill-upgrades">
                        <p>
                          <span>Unlocks at level 71:</span>{' '}
                          {character.skill1Details.upgrade_1}
                        </p>
                        <p>
                          <span>Unlocks at level 131:</span>{' '}
                          {character.skill1Details.upgrade_2}
                        </p>
                        <p>
                          <span>Unlocks at level 191:</span>{' '}
                          {character.skill1Details.upgrade_3}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon ${character.faction}`}>
                          Skill II
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">
                            {character.skill2Details.name}
                          </p>
                          <p className="skill-type">
                            Unlocks at level <strong>31</strong>
                          </p>
                        </div>
                      </div>
                      <div className={`additional-information`}>
                        <p>
                          Cooldown:{' '}
                          <span>
                            {character.skill2Details.cooldown ? (
                              <>{character.skill2Details.cooldown}s</>
                            ) : (
                              <>-</>
                            )}
                          </span>
                        </p>
                        <p>
                          Range: <span>{character.skill2Details.range}</span>
                        </p>
                      </div>
                      <div className={`skill-description`}>
                        <>
                          {renderRichText(character.skill2Description, options)}
                        </>
                      </div>
                      <div className="skill-upgrades">
                        <p>
                          <span>Unlocks at level 91:</span>{' '}
                          {character.skill2Details.upgrade_1}
                        </p>
                        <p>
                          <span>Unlocks at level 151:</span>{' '}
                          {character.skill2Details.upgrade_2}
                        </p>
                        <p>
                          <span>Unlocks at level 211:</span>{' '}
                          {character.skill2Details.upgrade_3}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon ${character.faction}`}>
                          Hero Focus
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Hero Focus</p>
                          <p className="skill-type">
                            Unlocks after reaching{' '}
                            <strong className="legendary ">Legendary+</strong>
                          </p>
                        </div>
                      </div>
                      <div className={`additional-information`}>
                        <p>
                          Cooldown: <span>-</span>
                        </p>
                        <p>
                          Range: <span>1 tile</span>
                        </p>
                      </div>
                      {character.focusSkillDescription ? (
                        <>
                          <div className={`skill-description`}>
                            <>
                              {renderRichText(
                                character.focusSkillDescription,
                                options
                              )}
                            </>
                          </div>
                          <div className="skill-upgrades">
                            <p>
                              <span>Level 2:</span>{' '}
                              {character.focusSkillDetails.upgrade_1}
                            </p>
                            <p>
                              <span>Level 3:</span>{' '}
                              {character.focusSkillDetails.upgrade_2}
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={`skill-description`}>
                            <p>Skill will be added soon!</p>
                          </div>
                          <div className="skill-upgrades">
                            <p>
                              <span>Level 2:</span> -
                            </p>
                            <p>
                              <span>Level 3:</span> -
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon ${character.faction}`}>
                          EX. Skill
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">
                            {character.exDetails.name}
                          </p>
                          <p className="skill-type">
                            Unlocks after reaching{' '}
                            <strong className="mythic">Mythic+</strong>
                          </p>
                        </div>
                      </div>
                      <div className={`additional-information`}>
                        <p>
                          Cooldown:{' '}
                          <span>
                            {character.exDetails.cooldown ? (
                              <>{character.exDetails.cooldown}s</>
                            ) : (
                              <>-</>
                            )}
                          </span>
                        </p>
                        <p>
                          Range: <span>{character.exDetails.range}</span>
                        </p>
                      </div>
                      <div className={`skill-description`}>
                        <>{renderRichText(character.exDescription, options)}</>
                      </div>
                      <div className="skill-upgrades">
                        <p>
                          <span>Unlocks at Ex. Weapon +5:</span>{' '}
                          {character.exDetails.upgrade_1}
                        </p>
                        <p>
                          <span>Unlocks at Ex. Weapon +10:</span>{' '}
                          {character.exDetails.upgrade_2}
                        </p>
                        <p>
                          <span>Unlocks at Ex. Weapon +15:</span>{' '}
                          {character.exDetails.upgrade_3}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon ${character.faction}`}>
                          Enhance Force{' '}
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Enhance Force</p>
                          <p className="skill-type">
                            Unlocks after reaching{' '}
                            <strong className="supreme">Supreme+</strong>
                          </p>
                        </div>
                      </div>
                      <div className={`additional-information`}>
                        <p>
                          Affects:{' '}
                          {character.ultimateDetails.enhance != '' && (
                            <span>Ultimate</span>
                          )}
                          {character.skill1Details.enhance != '' && (
                            <span>Skill 1</span>
                          )}
                          {character.skill2Details.enhance != '' && (
                            <span>Skill 2</span>
                          )}
                        </p>
                      </div>
                      <div className={`skill-description`}>
                        {character.ultimateDetails.enhance != '' && (
                          <p>{character.ultimateDetails.enhance}</p>
                        )}
                        {character.skill1Details.enhance != '' && (
                          <p>{character.skill1Details.enhance}</p>
                        )}
                        {character.skill2Details.enhance != '' && (
                          <p>{character.skill2Details.enhance}</p>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`faction ${character.faction}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>skills aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header faction ${character.faction}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Season 2 Charm
          </div>
          <p>
            To check the Charm's set bonus for{' '}
            <strong className={`faction ${character.faction}`}>
              {character.name}
            </strong>{' '}
            go to our Season 2 Charms guide.
          </p>
          <Row
            xs={1}
            md={2}
            lg={2}
            xxl={3}
            className="g-3 category-cards-container"
          >
            <CategoryCard
              title="Charms - Season 2"
              link="/afk-journey/guides/charms-season"
              image={
                <StaticImage
                  src="../images/afk/categories/category_charms.webp"
                  alt="Charms - Season 2"
                />
              }
            />
          </Row>
          <div className={`content-header faction ${character.faction}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gallery
          </div>
          <div className="info-box">
            <p>
              <strong className={`faction ${character.faction}`}>
                {character.name}
              </strong>{' '}
              <strong>images aren't available yet.</strong> They will be added
              soon.
            </p>
          </div>
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header faction ${character.faction}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header faction ${character.faction}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Review
          </div>
          {character.review ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                {character.review ? (
                  <>{renderRichText(character.review, options)}</>
                ) : (
                  <p>Review is pending.</p>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.faction}`}>
                  {character.name}
                </strong>{' '}
                <strong>review isn't available yet.</strong> It will be added
                soon!
              </p>
            </div>
          )}
          <div className={`content-header faction ${character.faction}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
          </div>
          {character.pros ? (
            <div className="section-analysis">
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.pros, options)}
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.cons, options)}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.faction}`}>
                  {character.name}
                </strong>{' '}
                <strong>pros & cons aren't available yet.</strong> They will be
                added soon!
              </p>
            </div>
          )}
          <div className={`content-header faction ${character.faction}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ratings
          </div>
          {character.ratings.afk != '1' ? (
            <>
              <h5>General Ratings</h5>
              <div className="detailed-ratings general">
                <RatingBox
                  game="afk"
                  rating={character.ratings.afk}
                  ratingName="AFK Stages"
                />
                <RatingBox
                  game="afk"
                  rating={character.ratings.battle}
                  ratingName="Battle Drill"
                />
                <RatingBox
                  game="afk"
                  rating={character.ratings.pvp}
                  ratingName="PVP"
                />
                <RatingBox
                  game="afk"
                  rating={character.ratings.dream_overall}
                  ratingName="Dream Realm (Overall)"
                />
              </div>
              <h5>Dream Realm Ratings</h5>
              <div className="detailed-ratings dream">
                <RatingBox
                  game="afk"
                  rating={character.ratings.dream_skyclops}
                  ratingName="Skyclops"
                />
                <RatingBox
                  game="afk"
                  rating={character.ratings.dream_croaker}
                  ratingName="Croaker"
                />
                <RatingBox
                  game="afk"
                  rating={character.ratings.dream_necrodragon}
                  ratingName="Necrodrakon"
                />
                <RatingBox
                  game="afk"
                  rating={character.ratings.dream_yeti}
                  ratingName="Snow Stomper"
                />
                <RatingBox
                  game="afk"
                  rating={character.ratings.dream_wolf}
                  ratingName="Lone Gaze"
                />
                <RatingBox
                  game="afk"
                  rating={character.ratings.dream_bear}
                  ratingName="Alpha Bear"
                />
                <RatingBox
                  game="afk"
                  rating={character.ratings.dream_orson}
                  ratingName="Orson"
                />
                <RatingBox
                  game="afk"
                  rating={character.ratings.dream_crystal}
                  ratingName="Crystal Beetle"
                />
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`faction ${character.faction}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>ratings aren't available yet.</strong> It will be
                  added soon!
                </p>
              </div>
            </>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Build' ? 'active' : ''}`}>
          <div className={`mobile-header faction ${character.faction}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Guides
            </p>
          </div>
          <div className={`content-header faction ${character.faction}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> EX Investment
          </div>
          <Alert variant="primary">
            <p className="intro">
              The EX investment information were created with the help of{' '}
              <strong>Seas0n</strong>, the creator of the{' '}
              <StaticImage
                src="../images/afk/icons/afkanalytica.png"
                alt="AFK Analytica"
              />{' '}
              <strong className="yellow">AFK Analytica</strong> community that
              supports AFK Arena and AFK Journey.
            </p>
            <p>
              You can find their website{' '}
              <a
                href="https://www.afkanalytica.com/"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>{' '}
              and their discord{' '}
              <a
                href="https://www.discord.gg/analytica"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          </Alert>
          {character.exPriority ? (
            <>
              <p>
                Since the <AFKItem name="Temporal Essence" /> and{' '}
                <AFKItem name="Twilight Essence" /> are very hard to obtain, you
                have to be really careful with the using them to upgrade the EX
                weapon. And in this guide we{' '}
                <strong>
                  ordered all characters by the priority when it comes to how
                  much they need investment
                </strong>{' '}
                in their EX weapon to work well and where should you stop to get
                the best bang for your buck.
              </p>
              <p>
                <strong>Why does the +8 breakpoint appear so often?</strong>
              </p>
              <p>
                Because the raw stats you get from upgrading the EX weapon help
                you more than the passive in some cases. Also, to upgrade the EX
                from +5 to +8 you need only 30x{' '}
                <AFKItem name="Temporal Essence" />, while the remaining two
                levels require additional 45x{' '}
                <AFKItem name="Temporal Essence" />. So you get the best bang
                for your buck stopping at +8.
              </p>
              <p>
                <strong>Color explanation:</strong>
              </p>
              <ul>
                <li>
                  <strong>+0</strong> - the base investment for the character,
                </li>
                <li>
                  <strong className="min">+5/8</strong> - the next step that you
                  should do when investing into that character,
                </li>
                <li>
                  <strong className="rec">+10/15</strong> - the final step you
                  should do when investing into that character - you can go
                  beyond that later though once you have the Essences to spare.
                </li>
              </ul>
              <div className="afk-ex-prio">
                <div className="afk-ex-row header">
                  <div className="column character">Character</div>
                  <div className="column priority">Priority</div>
                  <div className="column top-ex">PVE investment</div>
                  <div className="column top-ex">PVP investment</div>
                </div>
                <div className="afk-ex-row data">
                  <div className="column character">
                    <AFKCharacter
                      slug={character.slug}
                      mode="inline"
                      showLabel
                      enablePopover
                    />
                  </div>
                  <div className="column priority">
                    <p className={`prio-${character.exPriority}`}>
                      {character.exPriority === '1' && <>Low</>}
                      {character.exPriority === '2' && <>Medium</>}
                      {character.exPriority === '3' && <>High</>}
                      {character.exPriority === '4' && <>Very High</>}
                    </p>
                  </div>
                  <div className="column ex">
                    <p className="on-mobile">PVE investment</p>
                    {character.exNew ? (
                      <p>
                        <strong className="base">
                          +{character.exNew.base}
                        </strong>{' '}
                        {character.exNew.pve_early != 0 && (
                          <>
                            &rarr;{' '}
                            <strong className="low">
                              +{character.exNew.pve_early}
                            </strong>
                          </>
                        )}{' '}
                        {character.exNew.pve_late != 0 && (
                          <>
                            &rarr;{' '}
                            <strong className="end">
                              +{character.exNew.pve_late}
                            </strong>
                          </>
                        )}
                      </p>
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="column ex">
                    <p className="on-mobile">PVP investment</p>
                    {character.exNew ? (
                      <p>
                        <strong className="base">
                          +{character.exNew.base}
                        </strong>{' '}
                        {character.exNew.pvp_early != 0 && (
                          <>
                            &rarr;{' '}
                            <strong className="low">
                              +{character.exNew.pvp_early}
                            </strong>
                          </>
                        )}{' '}
                        {character.exNew.pvp_late != 0 && (
                          <>
                            &rarr;{' '}
                            <strong className="end">
                              +{character.exNew.pvp_late}
                            </strong>
                          </>
                        )}
                      </p>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`faction ${character.faction}`}>
                  {character.name}
                </strong>{' '}
                <strong>EX investment information aren't available yet.</strong>{' '}
                They will be added soon.
              </p>
            </div>
          )}
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default AFKCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' Guide | AFK Journey | Prydwen'}
      description={
        character.name +
        ' from AFK Journey. Check their skills, reviews, ratings and EX weapon investment.'
      }
      image={character.cardImage}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulAfkCharacter(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...AfkCharacterFieldsFragment
      }
    }
  }
`;
